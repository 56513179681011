import Image from 'next/image'
import React, {useState} from 'react'
import {useTranslation} from 'next-i18next'
import {
  ChargingTariff,
  DeliveryPointInfoResponse,
  ServiceDeliveryPointAvailability,
} from 'types/api-types'
import {getConnectorIcon} from 'util/connectorsType'
import classNames from 'classnames'

type ServicePointDetailChargerConnectorsProps = {
  extendedDataPoint?: DeliveryPointInfoResponse['serviceDeliveryPoint'] | null
  hideAvailability?: boolean
}

const ServicePointDetailChargerConnectors = ({
  extendedDataPoint,
  hideAvailability,
}: ServicePointDetailChargerConnectorsProps) => {
  const {t} = useTranslation()

  const [expandedConnectorType, setExpandedConnectorType] = useState<
    string | null
  >(null)

  const chargingPoints = extendedDataPoint?.chargingLocation.chargingPoints

  const amountOfChargers = chargingPoints?.length

  let availableCount: {[key: string]: number} = {}
  let totalCount: {[key: string]: number} = {}
  let power: {[key: string]: number} = {}
  let tariff: {[key: string]: ChargingTariff} = {}

  chargingPoints?.forEach(chargingPoint => {
    let connectorType = chargingPoint.chargingConnector!.connectorType[0]!

    if (availableCount[connectorType] == null) {
      availableCount[connectorType] = 0
    }
    if (
      chargingPoint.availability ===
      ServiceDeliveryPointAvailability['available']
    ) {
      availableCount[connectorType] = availableCount[connectorType]! + 1
    }
    if (totalCount[connectorType] == null) {
      totalCount[connectorType] = 0
    }
    totalCount[connectorType] = totalCount[connectorType]! + 1
    power[connectorType] = chargingPoint.chargingConnector?.power ?? 0
    tariff[connectorType] = chargingPoint.chargingConnector!.chargingTariff
  })

  const expandOrCollapseConnectorType = (connectorType: string) => {
    if (expandedConnectorType === connectorType) {
      setExpandedConnectorType(null)
    } else {
      setExpandedConnectorType(connectorType)
    }
  }

  const hasHourPrice = (connectorType: string) => {
    return tariff[connectorType].hourPrice.length > 0
  }

  const constructHourPriceLabel = (connectorType: string) => {
    if (!hasHourPrice(connectorType)) {
      return `${t('label_service_point_locator_price_per_hour')}: ${t(
        'label_service_point_locator_price_free',
      )}`
    }
    const hourPrice = tariff[connectorType].hourPrice[0].hourPriceValue
    const sanitizedHourPrice = hourPrice.toFixed(2).replace('.', ',')
    if (hourPrice === 0) {
      return `${t('label_service_point_locator_price_per_hour')}: ${t(
        'label_service_point_locator_price_free',
      )}`
    }

    return `${t(
      'label_service_point_locator_price_per_hour',
    )}: € ${sanitizedHourPrice}`
  }

  const hasStartupCost = (connectorType: string) => {
    return tariff[connectorType].startPrice > 0
  }

  const constructStartupCostLabel = (connectorType: string) => {
    const startPrice = tariff[connectorType].startPrice
    const sanitizedStartPrice = startPrice.toFixed(2).replace('.', ',')
    return `${t(
      'label_service_point_locator_price_startup',
    )}: € ${sanitizedStartPrice}`
  }

  const hasLimitPrice = (connectorType: string) => {
    const priceWithLimit = tariff[connectorType].hourPrice?.find(
      e => e.limit != null,
    )
    return priceWithLimit !== undefined
  }

  const constructFirstPriceLimitLabel = (connectorType: string) => {
    const priceWithLimit = tariff[connectorType].hourPrice?.find(
      e => e.limit != null,
    )
    const limit = priceWithLimit?.limit
    if (!limit) {
      return ''
    }

    const hourString = convertLimitToHour(limit)

    let priceBeforeLabel = t('label_service_point_locator_price_limit_before')
      .replace(
        '||THE||',
        isMulti(limit)
          ? t('label_general_article_de')
          : t('label_general_article_het'),
      )
      .replace('||HOUR||', hourString)

    const sanitizedHourPrice = roundZero(priceWithLimit.hourPriceValue)
    let valueLabel = t(
      'label_service_point_locator_price_per_hour_value',
    ).replace('||VALUE||', sanitizedHourPrice)

    return `${priceBeforeLabel}: ${valueLabel}`
  }

  const constructSecondPriceLimitLabel = (connectorType: string) => {
    const priceWithLimit = tariff[connectorType].hourPrice?.find(
      e => e.limit != null,
    )
    const priceWithoutLimit = tariff[connectorType].hourPrice?.find(
      e => e.limit === undefined,
    )

    const limit = priceWithLimit?.limit
    if (!limit) {
      return ''
    }

    const hourString = convertLimitToHour(limit)

    let priceAfterLabel = t(
      'label_service_point_locator_price_limit_after',
    ).replace('||HOUR||', hourString)

    const sanitizedHourPrice = roundZero(priceWithoutLimit?.hourPriceValue)
    let valueLabel = t(
      'label_service_point_locator_price_per_hour_value',
    ).replace('||VALUE||', sanitizedHourPrice)

    return `${priceAfterLabel}: ${valueLabel}`
  }

  const isMulti = (limit: number) => {
    const hours = Math.floor(limit)
    const remainder = limit % 1
    if (hours === 1 && remainder === 0) {
      return false
    } else {
      return true
    }
  }

  const convertLimitToHour = (limit: number) => {
    const hours = Math.floor(limit)
    const remainder = limit % 1
    if (remainder === 0) {
      return `${hours} u`
    } else {
      const minutes = (60 * remainder).toFixed(0)
      return `${hours} u ${minutes}`
    }
  }

  const roundZero = (value?: number) => {
    if (value === undefined) {
      return ''
    }
    if (value % 1 === 0) {
      return value.toFixed(0)
    }
    return value.toFixed(2).replace('.', ',')
  }

  const connectorImageClass = (avalableCount: number) =>
    classNames({
      'brightness-250': avalableCount === 0,
    })

  return (
    <div>
      <div>
        <div className="flex w-full items-center justify-between bg-dats-s10 p-4 font-rubik text-base font-bold">
          <span>{t('label_service_point_locator_charging_points')}</span>
          <span className="text-sm text-dats-s6">{amountOfChargers}</span>
        </div>
        <div className="p-4">
          {Object.keys(availableCount).map(connectorType => (
            <div
              className="mb-2 flex flex-col justify-between"
              key={connectorType}
            >
              <div className="flex justify-between">
                <div className="flex">
                  <div className="mr-2 w-9">
                    <Image
                      src={getConnectorIcon(connectorType)}
                      alt="connector type"
                      className={connectorImageClass(
                        availableCount[connectorType],
                      )}
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-dats-s5">
                      {power[connectorType]} kW
                    </span>
                    <span className="text-xs text-dats-s6">{`€ ${tariff[connectorType].kwhPrice[0].kwhPriceValue} / kWh`}</span>
                  </div>
                </div>
                {hideAvailability ? null : availableCount[connectorType] ===
                  0 ? (
                  <div className="text-sm font-semibold text-dats-s7">
                    {`0 / ${totalCount[connectorType]}`}
                  </div>
                ) : (
                  <div className="text-sm font-semibold text-dats-s2">{`${availableCount[connectorType]} / ${totalCount[connectorType]}`}</div>
                )}
              </div>
              <div>
                <button
                  onClick={() => expandOrCollapseConnectorType(connectorType)}
                  className="my-2 flex items-center justify-start space-x-2"
                >
                  <div className="font-sans text-[12px] font-semibold text-dats-s3">
                    {t('label_service_point_locator_price_info')}
                  </div>

                  {expandedConnectorType === connectorType ? (
                    <svg
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.07767 6.43137C5.36665 6.72419 5.83595 6.72419 6.12493 6.43137L10.5636 1.93363C10.8526 1.64081 10.8526 1.16527 10.5636 0.872448C10.2747 0.579627 9.80536 0.579627 9.51638 0.872448L5.60015 4.84076L1.68391 0.87479C1.39493 0.581969 0.925634 0.581969 0.636656 0.87479C0.347677 1.16761 0.347677 1.64315 0.636656 1.93598L5.07536 6.43371L5.07767 6.43137Z"
                        fill="#005E75"
                        transform="scale(1, -1) translate(0, -7)"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.07767 6.43137C5.36665 6.72419 5.83595 6.72419 6.12493 6.43137L10.5636 1.93363C10.8526 1.64081 10.8526 1.16527 10.5636 0.872448C10.2747 0.579627 9.80536 0.579627 9.51638 0.872448L5.60015 4.84076L1.68391 0.87479C1.39493 0.581969 0.925634 0.581969 0.636656 0.87479C0.347677 1.16761 0.347677 1.64315 0.636656 1.93598L5.07536 6.43371L5.07767 6.43137Z"
                        fill="#005E75"
                      />
                    </svg>
                  )}
                </button>
                {expandedConnectorType === connectorType ? (
                  <div className="mb-4 flex flex-col space-y-2">
                    {hasStartupCost(connectorType) ? (
                      <div className="flex items-center justify-start space-x-2">
                        <div className="size-2 rounded-full bg-dats-background-icon"></div>
                        <span className="font-sans text-[12px] text-dats-neutral-darkest">
                          {constructStartupCostLabel(connectorType)}
                        </span>
                      </div>
                    ) : null}

                    {hasLimitPrice(connectorType) ? (
                      <div className="flex items-center justify-start space-x-2">
                        <div className="size-2 rounded-full bg-dats-background-icon"></div>
                        <span className="font-sans text-[12px] text-dats-neutral-darkest">
                          {constructFirstPriceLimitLabel(connectorType)}
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center justify-start space-x-2">
                        <div className="size-2 rounded-full bg-dats-background-icon"></div>
                        <span className="font-sans text-[12px] text-dats-neutral-darkest">
                          {constructHourPriceLabel(connectorType)}
                        </span>
                      </div>
                    )}

                    {hasLimitPrice(connectorType) ? (
                      <div className="flex items-center justify-start space-x-2">
                        <div className="size-2 rounded-full bg-dats-background-icon"></div>
                        <span className="font-sans text-[12px] text-dats-neutral-darkest">
                          {constructSecondPriceLimitLabel(connectorType)}
                        </span>
                      </div>
                    ) : null}

                    <div className="flex items-center justify-start space-x-2">
                      <div className="size-2 rounded-full bg-dats-background-icon"></div>
                      <span className="font-sans text-[12px] text-dats-neutral-darkest">
                        {t('label_service_point_locator_price_vat_info')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ServicePointDetailChargerConnectors
